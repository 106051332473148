import { ICustomColumn, ImportedDeals, ITransformedDeal } from '../../Types/types';
import { formatDate } from '../../../Tanso/components/Utils/utils';
import './ImportedDeals.css';
import { four, three, two, zero } from '../../constants';
import CopyUserToClipboard from './CopyUserToClipboard';
import AddToChart from '../ScenerioModelling/DealFunnel/AddToChart/AddToChart';
import { notify } from '../Toast/notify';
const handleCopy = () => {
  notify('success', 'Copied to clipboard!');
};
export const getColumns = (
  selectedYear: number | null,
  // eslint-disable-next-line no-unused-vars
  handleToggle?: (selectedItem: ImportedDeals) => void,
) => {
  const columns = [
    { header: 'Opportunity Name', accessorKey: 'OPPORTUNITY_NAME', size: 180 },
    { header: 'Business', accessorKey: 'BUSINESS_NAME', size: 180 },
    {
      header: 'Commodity',
      accessorKey: 'TRADE_COMMODITY_NAME',
      cell: ({ getValue }: { getValue: () => string }) => getValue().replace('(Deal)', ''),
    },
    {
      accessorKey: 'MAX_EQUITY_SCOPE',
      header: 'Scope 1 & 2 (Operational Control) (MTPA)',
      size: 230,
      cell: ({ getValue }: { getValue: () => string }) => Number(getValue()).toFixed(two),
    },
    {
      accessorKey: 'MAX_CARBON_EMISSIONS',
      header: 'Peak Net Absolute Emissions (MTPA)',
      size: 200,
      cell: ({ getValue }: { getValue: () => string }) => Number(getValue()).toFixed(two),
    },
    {
      header: 'Peak Annual Sales Volume (TWH)',
      accessorKey: 'MAX_QUANTITY',
      size: 200,
      cell: ({ getValue }: { getValue: () => string }) => Number(getValue()).toFixed(two),
    },
    { header: 'Deal Start Date', accessorKey: 'START_DATE', size: 150 },
    { header: 'Deal End Date', accessorKey: 'END_DATE', size: 150 },
    {
      header: 'Deal Originator',
      accessorKey: 'DEALMAKER_USERID',
      cell: ({ getValue }: { getValue: () => string }) =>
        CopyUserToClipboard({ getValue }, handleCopy),
      size: 200,
    },
  ];
  if (handleToggle) {
    const addToChartColumn = {
      header: 'Add to Chart',
      accessorKey: 'ADD_TO_CHART',
      cell: ({ row, getValue }: { row: { original: ImportedDeals }; getValue: () => string }) =>
        <AddToChart row={row} getValue={getValue} handleToggle={handleToggle} />,
      size: 120,
      enableSorting: false,
    };
    columns.splice(zero, zero, addToChartColumn as unknown as ICustomColumn);
    columns.splice(three, zero, { header: 'Country', accessorKey: 'COUNTRY_NAME', size: 150 });
  }
  if (selectedYear) {
    columns.push({
      header: `Sales Volume for ${selectedYear} (TWH)`,
      accessorKey: 'QUANTITY_PER_YEAR',
      size: 180,
      cell: ({ getValue }: { getValue: () => string }) => {
        const value = getValue();
        return value ? Number(value).toFixed(four) : '0.0000';
      },
    });
  }
  return columns;
};
export const getTransformedImportedDeals = (importedDeals: ImportedDeals[]) => {
  const groupedDeals = importedDeals.reduce(
    (acc: Record<string, ITransformedDeal>, deal: ImportedDeals) => {
      const {
        OPPORTUNITY_ID,
        IS_OPERATING_PLAN,
        TRADE_YEAR,
        QUANTITY,
        EQUITY_SCOPE,
        CARBON_EMISSION,
        START_DATE,
        END_DATE,
        ...rest
      } = deal;
      if (!acc[OPPORTUNITY_ID]) {
        acc[OPPORTUNITY_ID] = {
          ...rest,
          OPPORTUNITY_ID,
          IS_OPERATING_PLAN,
          QUANTITIES: [],
          MAX_EQUITY_SCOPE: EQUITY_SCOPE,
          MAX_CARBON_EMISSIONS: CARBON_EMISSION,
          MAX_QUANTITY: QUANTITY,
          QUANTITY_PER_YEAR: null,
          START_DATE: formatDate(new Date(START_DATE)),
          END_DATE: formatDate(new Date(END_DATE)),
          TRADE_YEAR,
          CARBON_EMISSION,
          ADD_TO_CHART: IS_OPERATING_PLAN,
        };
      } else {
        acc[OPPORTUNITY_ID].MAX_EQUITY_SCOPE = Math.max(
          acc[OPPORTUNITY_ID].MAX_EQUITY_SCOPE,
          EQUITY_SCOPE,
        );
        acc[OPPORTUNITY_ID].MAX_CARBON_EMISSIONS = Math.max(
          acc[OPPORTUNITY_ID].MAX_CARBON_EMISSIONS,
          CARBON_EMISSION,
        );
        acc[OPPORTUNITY_ID].MAX_QUANTITY = Math.max(acc[OPPORTUNITY_ID].MAX_QUANTITY, QUANTITY);
      }
      const yearIndex = acc[OPPORTUNITY_ID].QUANTITIES.findIndex(
        (q: { year: number }) => q.year === TRADE_YEAR,
      );
      if (yearIndex === -1) {
        acc[OPPORTUNITY_ID].QUANTITIES.push({ year: TRADE_YEAR, quantity: QUANTITY, emission: CARBON_EMISSION });
      }
      return acc;
    },
    {},
  );

  return Object.values(groupedDeals);
};

export const getFilterDataByCommodityAndEvp = (
  importedDeals: ImportedDeals[],
  selectedCommodity: string | null,
  selectedEVP: string | null,
) => {
  return importedDeals.filter((deals: ImportedDeals) => {
    const commodityMatch =
      !selectedCommodity ||
      selectedCommodity === 'ALL' ||
      deals.TRADE_COMMODITY_NAME.replace(' (Deal)', '') === selectedCommodity;
    const evpMatch = !selectedEVP || selectedEVP === 'ALL' || deals.EVP === selectedEVP;
    return commodityMatch && evpMatch;
  });
};

export const getSelectedYearImportedDeals = (
  filterDealsByCommodity: ImportedDeals[],
  selectedYear: number | null,
) => {
  return filterDealsByCommodity.map((deal: ImportedDeals) => {
    const selectedYearQuantity = deal.QUANTITIES.find(
      (q: { year: number }) => q.year === selectedYear,
    )?.quantity;
    return {
      ...deal,
      QUANTITY_PER_YEAR: selectedYearQuantity,
    };
  });
};
