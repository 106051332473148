import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import Chart from '../../Chart/Chart';
import {
  getCommodityAndEvpOptions,
  getContractedPlanLeFiltersData,
  getDeltaLeAndCommodity,
  getManualLeDataForLeChart,
  getSummedUpLeData,
  plannedVsEstimatedSeriesData,
  transformedPlanData,
} from '../../../utils';
import {
  fetchplannedVolume,
  fetchCommittedVolume,
  fetchKpiMovedDeals,
} from '../../../api/services';
import { ITransformDataObject, PlannedVsCommitted, YearTypeValue } from '../../../Types/types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { hideSpinner, showSpinner } from '../../../redux/datavisualization/datavisualizationSlice';
import { fiveHundred, twoThousand } from '../../../constants';
import {
  setCommittedVolumeData,
  setPlannedVolumeData,
} from '../../../redux/dashboard/dashboardSlice';
import { ViewDetailsDiv } from '../OverallPortfolioChart/index.styles';
import { Flexbox } from '@sede-x/shell-ds-react-framework';
import { StyledContainer } from '../ScenerioModellingChart/index.styles';
import ViewDetailsButton from '../../ViewDetailsButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { CONTRACTED_VS_PLAN_LE } from '../../../../commonUtils/AppRouter/constants';
import ContractedPlanLeFilters from '../ContractedVsPlanVsLeDetails/ContractedPlanLeFilters/ContractedPlanLeFilters';
import { contractedVsPlanVsLeHighchart } from './contractedVsPlanVsLeHighchart';
import { LeftDividerVertical } from '../../../../commonUtils/Common.styles';
import VoumeEmissionToggle from '../../VoumneEmissionToggle/VolumeEmissionToggle';

const ContractedVsPlanVsLe = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedInUserRegion, userEvp } = useAppSelector((state) => state.dashboard);
  const isDetailsPage = location ? location.pathname.includes(CONTRACTED_VS_PLAN_LE) : false;
  const [plannedVolume, setPlannedVolume] = useState<PlannedVsCommitted[]>([]);
  const [committedVolume, setCommittedVolume] = useState<PlannedVsCommitted[]>([]);
  const [kpiMovedDeals, setKpiMovedDeals] = useState<PlannedVsCommitted[]>([]);
  const [groupedSeriesData, setGroupedSeriesData] = useState<ITransformDataObject[]>([]);
  const [uniqueYears, setUniqueYears] = useState<YearTypeValue[]>([]);
  const [uniqueSources, setUniqueSources] = useState<string[]>([]);
  const [isToggle, setIsToggle] = useState<boolean>(true);
  const [selectedCommodity, setSelectedCommodity] = useState<string>('ALL');
  const [selectedEvp, setSelectedEvp] = useState<string>('ALL');
  const [commodityOptions, setCommodityOptions] = useState<string[]>([]);
  const [evpOptions, setEvpOptions] = useState<string[]>([]);
  const [isImportedDealsShow, setIsImportedDealsShow] = useState<boolean>(false);

  const getData = useCallback(async () => {
    dispatch(showSpinner());
    const plannedVolumeResponse = await fetchplannedVolume(loggedInUserRegion, userEvp);
    const committedVolumeResponse = await fetchCommittedVolume(loggedInUserRegion, userEvp);
    const kpiMovedDealsResponse = await fetchKpiMovedDeals(loggedInUserRegion, userEvp);
    const plannedVolumeData = plannedVolumeResponse?.data ?? [];
    const committedVolumeData = committedVolumeResponse?.data ?? [];
    const kpiMovedDealsData = kpiMovedDealsResponse?.data ?? [];
    plannedVolumeData && setPlannedVolume(plannedVolumeData);
    committedVolumeResponse && setCommittedVolume(committedVolumeData);
    committedVolumeResponse && setKpiMovedDeals(kpiMovedDealsData);
    const { uniqueCommodities, uniqueEvp } = getCommodityAndEvpOptions([
      ...plannedVolumeData,
      ...committedVolumeData,
      ...kpiMovedDealsData,
    ]);
    uniqueCommodities && setCommodityOptions(['ALL', ...uniqueCommodities]);
    uniqueEvp && setEvpOptions(['ALL', ...(uniqueEvp as string[])]);
    dispatch(setPlannedVolumeData(plannedVolumeData));
    dispatch(setCommittedVolumeData(committedVolumeData));
    dispatch(hideSpinner());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getSeriesData = (
    plannedVolumeData: PlannedVsCommitted[],
    committedVolumeData: PlannedVsCommitted[],
    kpiMovedDealsData: PlannedVsCommitted[],
  ) => {
    const shouldShowData = isDetailsPage ? isImportedDealsShow : true;
    const {
      transformedDataGroupData,
      uniqueYears: newUniqueYears,
      uniqueSources: newUniqueSources,
    } = plannedVsEstimatedSeriesData(
      transformedPlanData(plannedVolumeData),
      committedVolumeData,
      shouldShowData ? kpiMovedDealsData : [],
      isToggle,
    );
    const filteredTransformedDataGroupData = transformedDataGroupData.map((TransformedData) => ({
      ...TransformedData,
      data: TransformedData.data.filter(
        (item): item is number | null => typeof item === 'number' || item === null,
      ),
    }));
    setGroupedSeriesData(filteredTransformedDataGroupData);
    setUniqueYears(newUniqueYears);
    setUniqueSources(newUniqueSources);
  };
  useEffect(() => {
    const filteredPlannedVolume = getContractedPlanLeFiltersData(
      plannedVolume,
      selectedCommodity,
      selectedEvp,
    );
    const filteredCommittedVolume = getContractedPlanLeFiltersData(
      committedVolume,
      selectedCommodity,
      selectedEvp,
    );
    const filteredKpiMovedDeals = getContractedPlanLeFiltersData(
      kpiMovedDeals,
      selectedCommodity,
      selectedEvp,
    );
    getSeriesData(filteredPlannedVolume, filteredCommittedVolume, filteredKpiMovedDeals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    plannedVolume,
    committedVolume,
    kpiMovedDeals,
    isToggle,
    isImportedDealsShow,
    isDetailsPage,
    selectedCommodity,
    selectedEvp,
  ]);

  const clearFilters = () => {
    setSelectedCommodity('ALL');
    setSelectedEvp('ALL');
    setIsImportedDealsShow(false);
  };
  const categoriesData = uniqueYears.map((x: { year: number }) => x.year.toString());
  // Calculate the maximum value from the data
  const maxValue = Math.max(
    ...groupedSeriesData.flatMap((series) =>
      series.data.filter((item): item is number => typeof item === 'number'),
    ),
  );
  // Determine the tick interval based on the maximum value
  const tickInterval = maxValue > twoThousand ? fiveHundred : undefined;
  const manualLeValue = getManualLeDataForLeChart(
    groupedSeriesData,
    selectedCommodity,
    loggedInUserRegion,
    userEvp || selectedEvp.replace('T&S ', ''),
  );
  const updatedSeriesData = groupedSeriesData.map((series) => {
    return {
      ...series,
      data: series.data.map((value) => {
        if (value !== null && series.name === 'Latest Estimate (LE)') {
          return getSummedUpLeData(manualLeValue, isToggle, selectedCommodity);
        }
        return value;
      }),
    };
  });
  const deltaLeAndCommodity = updatedSeriesData.length && getDeltaLeAndCommodity(updatedSeriesData, manualLeValue, isToggle, selectedCommodity) || [];
  // remove the below funciton once LE data is available
  const filteredData = deltaLeAndCommodity.filter(item => item.name !== "Gap to LE (Power)" && item.name !== "Gap to LE (Pipeline Gas)");
  const options =
    deltaLeAndCommodity?.length &&
    contractedVsPlanVsLeHighchart(filteredData, categoriesData, tickInterval, isToggle);

  return (
    <div data-testid='chart'>
      <Flexbox flexDirection='row' columnGap='20px' style={{ width: '100%' }}>
        {isDetailsPage && (
          <>
            <ContractedPlanLeFilters
              setSelectedCommodity={setSelectedCommodity}
              selectedCommodity={selectedCommodity}
              commodityOptions={commodityOptions}
              setSelectedEvp={setSelectedEvp}
              evpOptions={evpOptions}
              selectedEvp={selectedEvp}
              setIsImportedDealsShow={setIsImportedDealsShow}
              isImportedDealsShow={isImportedDealsShow}
              clearFilters={clearFilters}
            />
            <LeftDividerVertical />
          </>
        )}
        <StyledContainer>
          <ViewDetailsDiv>
            {isDetailsPage ? (
              // <ViewImportedDeals
              //   selectedCommodity={selectedCommodity}
              //   selectedEvp={selectedEvp}
              //   isImportedDealsShow={isImportedDealsShow}
              // />
              null
            ) : (
              <ViewDetailsButton
                handleClick={() => navigate(CONTRACTED_VS_PLAN_LE)}
                float='right'
              />
            )}
          </ViewDetailsDiv>
          <VoumeEmissionToggle isToggleOn={() => setIsToggle(!isToggle)} />
          <Chart
            chartKey='LatestEstimateVsPlanned'
            highcharts={Highcharts}
            options={options}
            source={uniqueSources?.join(', ')}
          // disclaimer={[CONTRACTED_VS_PLAN_DISCLAIMER]}
          />
        </StyledContainer>
      </Flexbox>
    </div>
  );
};

export default ContractedVsPlanVsLe;
