import React, { useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts';
import {
  EQUITY_GAS_FIRED_POWER_GENERATION,
  EQUITY_RENEWABLE_POWER_GENERATION,
  RENEWABLE,
  NONRENEWABLE,
  RESIDUAL_GRID,
  PARIG_FINANCE_REPORTING,
  PLANNING_ONE,
  POWER_INTENSITY_MODEL,
  POWER_INTENSITY_MODEL_RES,
  RENEWABLE_ATTRIBUTES_REC,
  REPORTONE,
  TANSO,
  THREE_P_GAS_FIRED_POWER_SUPPLY_PPA,
  THREE_P_POWER_SUPPLY_MARKET_POOL,
  THREE_P_RENEWABLE_POWER_SUPPLY_PPA,
  TANSO_FUTURE,
  EXTERNAL_GAS_SALES_VOLUMES_RENEWABLE,
  EXTERNAL_GAS_SALES_VOLUMES_PIPELINE,
  INLAND_SALES_VOLUME_NATURAL_GAS,
  EXTERNAL_POWER_SALES_RENEWABLE_CPPA,
  EXTERNAL_POWER_SALES_RENEWABLE_REC,
  EXTERNAL_POWER_SALES_GF,
  EXTERNAL_POWER_SALES_RESIDUAL_GRID,
  INLAND_SALES_VOLUME_PIPELINE_GAS,
  EXTERNAL_POWER_SALES_NON_RENEWABLE_OTHER,
} from '../../../api/constants';
import { StyledDiv } from '../CarbonIntensityChart/CarbonIntensityChart.styles';
import {
  StackedVerticalBarChartProps,
  TradeData,
  ProcessedDataItem,
  KeyValueData,
  YearProcessedDataItem,
} from './types';
import Chart from '../../Chart/Chart';
import { GAS_DISCLAIMER, POWER_DISCLAIMER, three } from '../../../constants';
import { highchartOptions } from './highchartOptions';
import { SummedValues } from '../CarbonIntensityChart/types';
import { getRename, mergeAndSumPipelineGas } from '../../CommodityOverview/Utils';
import { mergeActualAndPlanCommodity } from '../../../utils';
import { useAppSelector } from '../../../redux/hooks';
import { currentYear } from '../../../utils/utils';

declare module 'highcharts' {
  interface PointOptionsObject {
    tooltipText?: string;
  }
}

const VolumeCarbonEmissionChart: React.FC<StackedVerticalBarChartProps> = ({
  commodityFilteredValue,
  chartType,
  commodityVal,
}) => {
  const { isUserGlobalOrSetAsia, bizId } = useAppSelector((state) => ({
    isUserGlobalOrSetAsia: state.dashboard.isUserGlobalOrSetAsia,
    bizId: state.commodityOverview.bizId,
  }));
  const [chartKey, setChartKey] = useState(0);
  const prevCommodityFilteredValue = useRef<string | null>(null);
  const refreshChart = () => {
    setChartKey((prevKey) => prevKey + 1);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const currentStringified = JSON.stringify(commodityFilteredValue);
    if (currentStringified !== prevCommodityFilteredValue.current) {
      refreshChart();
      prevCommodityFilteredValue.current = currentStringified;
    }
  }, [commodityFilteredValue]);

  const KpiForNonRen = new Set([
    THREE_P_GAS_FIRED_POWER_SUPPLY_PPA,
    EQUITY_GAS_FIRED_POWER_GENERATION,
    EXTERNAL_POWER_SALES_GF,
    EXTERNAL_POWER_SALES_NON_RENEWABLE_OTHER
  ]);
  const KpiForResGrid = new Set([
    THREE_P_POWER_SUPPLY_MARKET_POOL,
    EXTERNAL_POWER_SALES_RESIDUAL_GRID,
  ]);
  const KpiForRenewable = new Set([
    THREE_P_RENEWABLE_POWER_SUPPLY_PPA,
    RENEWABLE_ATTRIBUTES_REC,
    EQUITY_RENEWABLE_POWER_GENERATION,
    EXTERNAL_POWER_SALES_RENEWABLE_CPPA,
    EXTERNAL_POWER_SALES_RENEWABLE_REC,
  ]);

  const historicalSources = new Set([
    TANSO,
    REPORTONE,
    POWER_INTENSITY_MODEL,
    POWER_INTENSITY_MODEL_RES,
  ]);
  const planningSources = new Set([PLANNING_ONE, PARIG_FINANCE_REPORTING, TANSO_FUTURE]);

  const historicalStartYear = currentYear - three;
  const planningEndYear = currentYear + 10;
  const actuals = 'actuals';
  const power = 'Power';
  const naturalGas = 'Natural Gas';
  const sales = 'sales';
  const emissions = 'emissions';

  const commodityNewFilteredValue = commodityFilteredValue;

  const planningAndhistoricaYearsMap = (
    data: TradeData[],
    sourcesHist: Set<string>,
    sourcesPlan: Set<string>,
    kpiSet: Set<string>,
    carbonEmissionKey: keyof TradeData,
  ) => {
    const historicalYearsMap = new Map<string, number>();
    const planningYearsMap = new Map<string, number>();
    const processItem = (
      item: TradeData,
      yearKeySuffix: string,
      targetMap: Map<string, number>,
    ) => {
      const yearKey = `${item.TRADE_YEAR}-${yearKeySuffix}`;
      const currentValue = targetMap.get(yearKey) ?? 0;
      targetMap.set(yearKey, currentValue + Number(item[carbonEmissionKey]));
    };
    data.forEach((item) => {
      if (kpiSet.has(item.KPI)) {
        if (
          sourcesHist.has(item.SOURCE) &&
          item.TRADE_YEAR >= historicalStartYear &&
          item.TRADE_YEAR <= currentYear
        ) {
          processItem(item, 'actuals', historicalYearsMap);
        }
        if (
          sourcesPlan.has(item.SOURCE) &&
          item.TRADE_YEAR >= currentYear &&
          item.TRADE_YEAR < planningEndYear
        ) {
          processItem(item, 'plan', planningYearsMap);
        }
      }
    });
    return [historicalYearsMap, planningYearsMap];
  };

  function getTotalQuantityByYear(
    data: TradeData[],
    sourcesHist: Set<string>,
    sourcesPlan: Set<string>,
    kpiSet: Set<string>,
  ): Map<string, number> {
    const [historicalYearsMap, planningYearsMap] = planningAndhistoricaYearsMap(
      data,
      sourcesHist,
      sourcesPlan,
      kpiSet,
      'QUANTITY',
    );

    const combinedMap = new Map<string, number>();

    historicalYearsMap.forEach((value, key) => {
      combinedMap.set(key, value);
    });

    planningYearsMap.forEach((value, key) => {
      const currentValue = combinedMap.get(key);
      if (currentValue || currentValue === 0) {
        combinedMap.set(key, currentValue + value);
      } else {
        combinedMap.set(key, value);
      }
    });

    return combinedMap;
  }

  function getTotalIntensityByYear(
    data: TradeData[],
    sourcesHist: Set<string>,
    sourcesPlan: Set<string>,
    kpiSet: Set<string>,
  ): Map<string, number> {
    const [historicalYearsMap, planningYearsMap] = planningAndhistoricaYearsMap(
      data,
      sourcesHist,
      sourcesPlan,
      kpiSet,
      'CARBON_EMISSION',
    );

    const combinedMap = new Map<string, number>();

    historicalYearsMap.forEach((value, key) => {
      combinedMap.set(key, value);
    });

    planningYearsMap.forEach((value, key) => {
      const currentValue = combinedMap.get(key);
      if (currentValue || currentValue === 0) {
        combinedMap.set(key, currentValue + value);
      } else {
        combinedMap.set(key, value);
      }
    });

    return combinedMap;
  }

  function processAndSortData(kpiSet: Set<string>): ProcessedDataItem[] {
    const data = commodityNewFilteredValue;
    const sourcesHist = historicalSources;
    const sourcesPlan = planningSources;
    const totalQuantityByYear =
      chartType === sales
        ? getTotalQuantityByYear(data, sourcesHist, sourcesPlan, kpiSet)
        : getTotalIntensityByYear(data, sourcesHist, sourcesPlan, kpiSet);

    const processedData: ProcessedDataItem[] = [];

    totalQuantityByYear.forEach((value, key) => {
      const [year, type] = key.split('-');
      processedData.push({
        year,
        type,
        value,
        yearType: `${year}-${type}`,
      });
    });

    processedData.sort((a, b) => {
      if (a.type === actuals && b.type !== actuals) {
        return -1;
      }
      if (a.type !== actuals && b.type === actuals) {
        return 1;
      }
      return a.year.localeCompare(b.year);
    });

    return processedData;
  }

  const renewableprocessedData = processAndSortData(KpiForRenewable);
  const nonrenewableprocessedData = processAndSortData(KpiForNonRen);
  const residualprocessedData = processAndSortData(KpiForResGrid);
  const KpiForNaturalGasRenewable = new Set([EXTERNAL_GAS_SALES_VOLUMES_RENEWABLE]);
  const KpiForNaturalGasPipeline = new Set([
    EXTERNAL_GAS_SALES_VOLUMES_PIPELINE,
    INLAND_SALES_VOLUME_NATURAL_GAS,
  ]);

  const naturalGasRenewablprocessedData = processAndSortData(KpiForNaturalGasRenewable);
  const naturalGasPipelineprocessedData = processAndSortData(KpiForNaturalGasPipeline);
  const uniqueKPIsSet = new Set(commodityNewFilteredValue.map((item) => item.KPI));
  const uniqueKPIs = Array.from(uniqueKPIsSet);

  const sourcesSet = new Set(commodityNewFilteredValue.map((item) => item.SOURCE));
  const uniqueSources = Array.from(sourcesSet);

  const processAndSortKPI = (kpiSet: Set<string>) => {
    return processAndSortData(kpiSet);
  };

  const processedDataByKPI: KeyValueData[] = uniqueKPIs.map((kpiSet) => ({
    key: kpiSet,
    value: processAndSortKPI(new Set([kpiSet])),
  }));

  let combinedArrayWithKey: SummedValues[];

  if (commodityVal === power) {
    combinedArrayWithKey = [
      {
        name: 'Residual-Grid',
        data: residualprocessedData,
      },
      {
        name: 'Non-Renewable',
        data: nonrenewableprocessedData,
      },
      {
        name: 'Renewable',
        data: renewableprocessedData,
      },
    ];
  } else if (commodityVal === naturalGas) {
    combinedArrayWithKey = [
      {
        name: 'External Gas Sales Volumes (Pipeline)',
        data: naturalGasPipelineprocessedData,
      },
      {
        name: 'External Gas Sales Volumes (Renewable)',
        data: naturalGasRenewablprocessedData,
      },
    ];
  } else {
    combinedArrayWithKey = processedDataByKPI.map((dataObj) => {
      return {
        name: dataObj.key,
        data: dataObj.value,
      };
    });
  }

  const combinedDataArray: ProcessedDataItem[] = processedDataByKPI.flatMap(
    (dataObj) => dataObj.value,
  );

  const reducedArray = combinedDataArray.map(({ year, type }) => ({ year, type }));
  const yearTypeSet = new Set(reducedArray.map(({ year, type }) => `${year}-${type}`));

  const uniqueYearTypeArray = Array.from(yearTypeSet);
  uniqueYearTypeArray.sort((a, b) => {
    const [yearA, typeA] = a.split('-');
    const [yearB, typeB] = b.split('-');

    if (typeA === actuals && typeB !== actuals) {
      return -1;
    }
    if (typeA !== actuals && typeB === actuals) {
      return 1;
    }
    return yearA.localeCompare(yearB);
  });
  const resultYearArray = uniqueYearTypeArray.map((key) => {
    const [year, type] = key.split('-');
    return {
      year,
      type,
    };
  });

  const mapMissingData = (
    data: YearProcessedDataItem[],
    processedData: ProcessedDataItem[],
  ): ProcessedDataItem[] => {
    return data.map(({ year, type }) => {
      const matchingItem = processedData.find((item) => item.year === year && item.type === type);
      return matchingItem ?? { type, value: 0, year, yearType: `${year}-${type}` };
    });
  };
  const processedCombinedArrayWithKey = combinedArrayWithKey.map((dataObj) => ({
    name: getRename(dataObj.name),
    data: mapMissingData(resultYearArray, dataObj.data),
    currentYearPlanAndActualData: { actual: 0, plan: 0 },
  }));
  processedCombinedArrayWithKey.sort((a, b) => {
    if ([INLAND_SALES_VOLUME_PIPELINE_GAS, INLAND_SALES_VOLUME_NATURAL_GAS].includes(a.name)) {
      return 1;
    } else if (
      [INLAND_SALES_VOLUME_PIPELINE_GAS, INLAND_SALES_VOLUME_NATURAL_GAS].includes(b.name)
    ) {
      return -1;
    } else {
      return 0;
    }
  });

  const charTitleType = chartType === emissions ? 'Carbon Emissions' : 'Sales Volume';

  const chartSubtitleType = chartType === emissions ? 'carbon emissions' : 'volumes';
  if (uniqueKPIs.length === 0) {
    return null;
  }

  const chartSubtitleKPI =
    commodityVal === power ? `${RENEWABLE}, ${NONRENEWABLE}, and ${RESIDUAL_GRID}` : commodityVal;
  mergeActualAndPlanCommodity(processedCombinedArrayWithKey);
  const options = highchartOptions({
    commodityVal,
    charTitleType,
    chartSubtitleType,
    chartSubtitleKPI,
    resultYearArray,
    chartType,
    processedCombinedArrayWithKey: mergeAndSumPipelineGas(processedCombinedArrayWithKey),
    bizId
  });

  const disclaimerForGas = [
    'Please note that Renewable Gas Actual Volumes before 2025 are de minimis relative to this chart',
    ...(isUserGlobalOrSetAsia ? [GAS_DISCLAIMER] : []),
  ];

  const disclaimerForPower = isUserGlobalOrSetAsia ? [POWER_DISCLAIMER] : [];

  const disclaimer = commodityVal === power ? disclaimerForPower : disclaimerForGas;

  return (
    <StyledDiv>
      <Chart
        chartKey={`${commodityVal}${charTitleType}bySource`}
        key={chartKey}
        highcharts={Highcharts}
        options={options}
        source={uniqueSources.join(', ')}
        {...(disclaimer.length > 0 && { disclaimer })}
      />
    </StyledDiv>
  );
};

const MemoizedComponent = React.memo(VolumeCarbonEmissionChart);

export default MemoizedComponent;
