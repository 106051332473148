import React, { useEffect, useState } from 'react';
import {
  fetchCarbonemissionDeltaView,
  fetchMCRStatus,
  fetchNetCarbonIntensity,
  fetchRagStatus,
  getImportedDeals,
  overallCarbonStatus,
} from '../../api/services';
import './carbondashboard.css';
import OverallCarbonStatus from './OverallCarbonStatus/OverallCarbonStatus';
import {
  BUSINESS_SPLIT_SUB_TITLE,
  BUSINESS_SPLIT_TITLE,
  MARGIN_TO_CARBON_RATIO,
  SE_IPU_REGION_TABLE_COLUMNS,
  TOTAL_CARBON_EMISSION,
} from '../../constants';
import { ICarbonData, INciTileData, ITableRowData, IUserInfo } from '../../Types/types';
import CarbonEmissionCard from './CarbonEmissionCard/CarbonEmissionCard';
import TableCard from './TableCard/TableCard';
import { calculateEmissionsBySeIpuRegion, getNciTileData } from '../../utils';
import WelcomeDashboard from './WelcomeDashboard/WelcomeDashboard';
import { currentYear } from '../../utils/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { hideSpinner, showSpinner } from '../../redux/datavisualization/datavisualizationSlice';
import { mockIpuTableRows } from '../CustomTable/__mocks__/TableMockData';
import { notify } from '../Toast/notify';
import { FETCH_ERROR } from '../../utils/constants';
import appInsights from '../../../commonUtils/appInsights';
import { Grid } from '@sede-x/shell-ds-react-framework';
import { DasboardContainer } from './CarbonDashBoard.styles';
import { setImportedDealsData, setNciData } from '../../redux/dashboard/dashboardSlice';
import NciTileCard from './NciTileCard/NciTileCard';
interface CarbonDashBoardProps {
  user: IUserInfo;
}

const CarbonDashBoard: React.FC<CarbonDashBoardProps> = ({ user }) => {
  const dispatch = useAppDispatch();
  const sliceIndex = 4;
  const [rowsData, setRowsData] = useState<ITableRowData[]>(mockIpuTableRows.slice(0, sliceIndex));
  const [carbonStatusData, setCarbonStatusData] = useState([]);
  const [ragStatusData, setRagStatusData] = useState<ICarbonData[]>([]);
  const [mCRStatusData, setMCRStatusData] = useState<ICarbonData[]>([]);
  const [nciTileData, setNciTileData] = useState<INciTileData>();
  const { committedVolumeData, nciData, loggedInUserRegion, userEvp } = useAppSelector(
    (state) => state.dashboard,
  );
  useEffect(() => {
    if (nciData?.length > 0) {
      setNciTileData(getNciTileData(committedVolumeData, nciData));
    }
  }, [nciData]);

  const fetchAllData = async () => {
    dispatch(showSpinner());
    try {
      const [
        carbonEmissionData,
        carbonStatus,
        getRagStatus,
        getMCRStatus,
        netCarbonIntensity,
        importedDeals,
      ] = await Promise.all([
        fetchCarbonemissionDeltaView(loggedInUserRegion, userEvp),
        overallCarbonStatus(loggedInUserRegion, userEvp),
        fetchRagStatus(loggedInUserRegion, userEvp),
        fetchMCRStatus(loggedInUserRegion, userEvp),
        fetchNetCarbonIntensity(loggedInUserRegion, userEvp),
        getImportedDeals(loggedInUserRegion, userEvp),
      ]);
      const groupedData =
        carbonEmissionData && calculateEmissionsBySeIpuRegion(carbonEmissionData.data);
      groupedData && setRowsData(groupedData);
      carbonStatus && setCarbonStatusData(carbonStatus.data);
      getRagStatus && setRagStatusData(getRagStatus.data);
      getMCRStatus && setMCRStatusData(getMCRStatus.data);
      netCarbonIntensity && dispatch(setNciData(netCarbonIntensity.data));
      importedDeals && dispatch(setImportedDealsData(importedDeals.data));
    } catch (e) {
      notify('error', FETCH_ERROR);
      dispatch(hideSpinner());
    }
    dispatch(hideSpinner());
  };

  //on page load
  useEffect(() => {
    fetchAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isAlreadyLoggedInStorage = 'isAlreadyLoggedInStorage';
    const value: string | null = localStorage.getItem(isAlreadyLoggedInStorage);

    if (!value || value !== 'true') {
      appInsights.trackEvent({
        name: 'User logged in',
        properties: {
          email: user?.email,
          username: user?.preferred_username,
          role: user?.aud,
        },
      });
      localStorage.setItem(isAlreadyLoggedInStorage, 'true');
    }
  }, [user]);

  return (
    <DasboardContainer>
      <Grid columns='33% 20.5% auto 20.5%' gap='20px'>
        <Grid.Cell>
          <WelcomeDashboard />
        </Grid.Cell>
        <Grid.Cell>
          <CarbonEmissionCard
            currentYear={currentYear}
            emissionType={TOTAL_CARBON_EMISSION}
            data={ragStatusData}
          />
        </Grid.Cell>
        <Grid.Cell>
          <NciTileCard data={nciTileData as INciTileData} />
        </Grid.Cell>
        <Grid.Cell>
          <CarbonEmissionCard
            currentYear={currentYear}
            emissionType={MARGIN_TO_CARBON_RATIO}
            data={mCRStatusData}
          />
        </Grid.Cell>
      </Grid>
      <Grid columns={'33% auto'} gap='20px'>
        <Grid.Cell>
          <OverallCarbonStatus user={user} data={carbonStatusData} />
        </Grid.Cell>
        <Grid.Cell>
          <TableCard
            title={`${BUSINESS_SPLIT_TITLE} (${currentYear})`}
            subTitle={BUSINESS_SPLIT_SUB_TITLE}
            columns={SE_IPU_REGION_TABLE_COLUMNS}
            rowsData={rowsData}
          />
        </Grid.Cell>
      </Grid>
    </DasboardContainer>
  );
};
export default CarbonDashBoard;
