import { CustomButtonProps } from '../../Types/types';
import './CustomButton.css';
import { Button } from '@sede-x/shell-ds-react-framework';


const CustomButton: React.FC<CustomButtonProps> = ({label, link}) => {
  const handleOnClick = () => {
    window.open(link, '_blank');
  };
  return (
    <Button variant='outlined' className='btn-style link-btn' onClick={handleOnClick}>
      {label}
    </Button>
  );
};

export default CustomButton;
