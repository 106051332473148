import React, { useContext } from 'react';
import { GlobalHeader } from '@sede-x/glass-design-library';
import { Menu, MenuItem } from '@sede-x/shell-ds-react-framework';
import './MainHeader.css';
import { FEEDBACK, FEEDBACK_LINK, HELP, HELP_LINK, LOGOUT, SUB_TITLE_DASHBOARD, TITLE_DASHBOARD } from '../../CarbonDashboard/constants';
import { AppContext } from '../../Tanso/Context/AppContext';
import { IMenuItem } from '../../Tanso/Types/types';
import TabMenu from './TabMenu';
import { Link, useLocation } from 'react-router-dom';
import { getActiveKey } from './utils';
import { useAppSelector } from '../../CarbonDashboard/redux/hooks';
import CustomButton from '../../CarbonDashboard/components/CustomButton/CustomButton';

interface MainHeaderProps {
  fullname: string;
  emailAddress: string;
  logout: () => void;
}

const MainHeader: React.FC<MainHeaderProps> = ({ fullname, emailAddress, logout }) => {
  const { state } = useContext(AppContext).appData;
  const { showScenarioModellingPage } = useAppSelector((states) => states.dashboard);
  const location = useLocation();
  const itemRender = ({ path, children }: { path: string; children: React.ReactNode }) => (
    <Link to={path}>{children}</Link>
  );
  const routerMenuItems = () => {
    const baseItems: IMenuItem[] = [
      { key: '0', label: 'Dashboard', path: '/Dashboard' },
      { key: '1', label: 'Commodity Overview', path: '/CommodityOverView' },
    ];
    const scenarioModellingItem: IMenuItem = {
      key: '2',
      label: 'Scenario Modelling',
      path: '/ScenarioModel',
    };
    if (showScenarioModellingPage) {
      baseItems.push(scenarioModellingItem);
    }
    const ncfDashboardItem: IMenuItem = {
      key: '3',
      label: 'NCF Dashboard',
      children: [
        { key: '3-1', label: 'Analytics Home', path: '/ncf-reporting' },
        { key: '3-2', label: 'BIA Report', path: '/bia-report' },
        { key: '3-3', label: 'Data Snapshot Report', path: '/snapshot-report' },
        { key: '3-4', label: 'Report Breakdown', path: '/report-breakdown' },
        { key: '3-5', label: 'Data Reconciliation Report', path: '/data-reconciliation' },
      ],
    };
    const ncfReportingItem: IMenuItem = {
      label: 'NCF Reporting',
      key: '4',
      children: [
        { key: '4-1', label: 'Edit Report', path: '/viewEditReport' },
        { key: '4-2', label: 'Audit Report', path: '/viewauditreport' },
        ...(state.showConfigRulePage
          ? [{ key: '4-3', label: 'Configure Rule', path: '/configurerules' }]
          : []),
        { key: '4-4', label: 'Sectoral Performance', path: '/sectoralPerformance' },
        { key: '4-5', label: 'NCF Snapshot', path: '/publishSnapshotReport' },
      ],
    };
    // Conditionally include the NCF Reporting item based on the email check
    if (!state.isUserSeVpGm) {
      baseItems.push(ncfDashboardItem);
      baseItems.push(ncfReportingItem);
    }
    return baseItems;
  };
  const activeKey = getActiveKey(routerMenuItems(), location?.pathname);
  return (
    <GlobalHeader
      hideGlassHub
      avatar={{
        description: emailAddress,
        title: fullname,
        dropdownOverlay: (
          <Menu>
            <MenuItem key='logout' onClick={logout}>
              {LOGOUT}
            </MenuItem>
          </Menu>
        ),
      }}
      subtitle={SUB_TITLE_DASHBOARD}
      title={TITLE_DASHBOARD}
      centerComponent={
        <TabMenu items={routerMenuItems()} itemRender={itemRender} activeKey={activeKey} />
      }
      rightComponent={
        <span className='display-flex'>
            <CustomButton label={HELP} link={HELP_LINK} />
            <CustomButton label={FEEDBACK} link={FEEDBACK_LINK}/>
          </span>
      }
    />
  );
};

export default MainHeader;
