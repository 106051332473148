import { BaseTable } from '@sede-x/shell-ds-react-framework';
import { SortingState, getSortedRowModel } from '@tanstack/react-table';
import React, { useState } from 'react';
import { ICustomColumn, ImportedDeals } from '../../Types/types';
import { convertCarbonEmissionInMTPA } from '../ScenerioModelling/utils';
interface ImportedDealsTableProps {
  importedDeals: ImportedDeals[];
  cols: ICustomColumn[];
}
const ImportedDealsTable: React.FC<ImportedDealsTableProps> = ({ importedDeals, cols }) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const tableOptions = {
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  };
  const updatedImportedDeals = importedDeals.map((deal) => {
    const { CARBON_EMISSION_UNIT } = deal;
    return CARBON_EMISSION_UNIT === 'MTPA' ? deal : convertCarbonEmissionInMTPA(deal);
  });

  return (
    <BaseTable
      className='imported-deals-table'
      stickyHeader
      stickyFooter
      useColumnsSizing
      maxHeight={400}
      columns={cols}
      data={updatedImportedDeals}
      tableOptions={tableOptions}
    />
  );
};

export default ImportedDealsTable;
