/* eslint-disable no-unused-vars */
import React from 'react';
import { IProduct } from '../../../Types/types';
import RowGroups from '../ScenerioTable';
import { getBusinessPlanTableKpisData } from '../utils';

interface BusinessPlanProps {
  selectedRegion: string;
  selectedRegionData: IProduct;
  handleValueChangeWrapper: (
    rowId: string,
    category: string,
    product: string,
    year: number,
    newValue: number,
  ) => void;
  handleRevertToOriginalWrapper: (
    rowId: string,
    category: string,
    product: string,
    year: number,
  ) => void;
}
const BusinessPlan: React.FC<BusinessPlanProps> = ({
  selectedRegion,
  selectedRegionData,
  handleValueChangeWrapper,
  handleRevertToOriginalWrapper,
}) => {
  const tableData = getBusinessPlanTableKpisData(selectedRegionData?.categoryData);

  return (
    <div data-testid='business-plan'>
      {selectedRegionData && (
        <RowGroups
          tableData={tableData}
          selectedRegion={selectedRegion}
          onInputChange={handleValueChangeWrapper}
          onRevertToOriginal={handleRevertToOriginalWrapper}
        />
      )}
    </div>
  );
};

export default BusinessPlan;
