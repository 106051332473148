import React, { useEffect, useState } from 'react';
import Highcharts, { XAxisPlotBandsLabelOptions } from 'highcharts';
import { StyledDiv, ChartContainer } from './index.styles';
import { formatRegionName, ShellFont, toolTipFormatter, typeBasedChart } from '../../../utils';
import Chart from '../../Chart/Chart';
import {
  CHART_AXIS_STYLE,
  POWER_DISCLAIMER,
  SCENERIO_TABLE_SOURCE,
  point5,
} from '../../../constants';
import { ChartData, PointsData } from '../../../Types/types';
import { currentYear } from '../../../utils/utils';
import { useAppSelector } from '../../../redux/hooks';
import { SNAE } from '../../../api/constants';
import { getActualLastYearIndex } from '../../CommodityOverview/Utils';

interface ScenerioModellingChartProps {
  scenerioModellingdata: ChartData[];
  chartType: string;
}

const ScenerioModellingChart: React.FC<ScenerioModellingChartProps> = ({
  scenerioModellingdata,
  chartType,
}) => {
  const [indexOfLastActuals, setIndexOfLastActuals] = useState(-1);
  const { loggedInUserRegion } = useAppSelector(
    (state) => state.dashboard,
  );
  const showEmissionChart = false;
  let updatedGroupedData = scenerioModellingdata;
  updatedGroupedData = updatedGroupedData.filter((x) => x.data.length > 0);
  const commodityOrder = [
    'Pipeline Gas (Deal)',
    'Power (Deal)',
    'Pipeline Gas',
    'Power',
    'Total Plan',
  ];
  updatedGroupedData.sort((a, b) => {
    return commodityOrder.indexOf(a.name) - commodityOrder.indexOf(b.name);
  });

  function findUniqueSources(data: ChartData[]): string[] {
    const uniqueSources: Set<string> = new Set();

    data.forEach((group) => {
      group.data.forEach((tradeData) => {
        if (tradeData.SOURCE !== undefined) {
          uniqueSources.add(tradeData.SOURCE);
        }
      });
    });

    return Array.from(uniqueSources);
  }

  const uniqueSourcesString: string = findUniqueSources(updatedGroupedData).join(', ');

  const regionDisplay =
    loggedInUserRegion?.toLowerCase() === 'europe'
      ? 'Europe/Africa'
      : formatRegionName(loggedInUserRegion);

  const naeChartTitle = loggedInUserRegion ? `${SNAE} for ${regionDisplay}` : `${SNAE}`;
  const reducedArray = updatedGroupedData[0].data.map(({ year, type }) => ({ year: year as number, type }));
  const uniqueYears = reducedArray.map((x) => x?.year?.toString());

  useEffect(() => {
      setIndexOfLastActuals(getActualLastYearIndex(reducedArray));
  }, [reducedArray]);

  const planLabel = {
    text: 'Plan',
    style: {
      fontFamily: ShellFont,
      fontSize: '11px',
      fontWeight: '600',
      lineHeight: '16px',
      textAlign: 'right',
      color: '#343434',
    },
    verticalAlign: 'bottom',
    y: 40,
  } as XAxisPlotBandsLabelOptions;
  const options: Highcharts.Options = {
    chart: {
      type: 'area',
      height: 500,
      marginBottom: 100,
      style: {
        fontFamily: 'Shell Font, Arial, sans-serif',
      },
    },
    title: {
      text: `${chartType === 'basecase' ? 'Base Case' : naeChartTitle}`,
      align: 'left',
    },
    subtitle: {
      align: 'left',
      text: 'An overview of planned NAE target vs NAE scenario.',
      style: {
        fontSize: '14px',
        fontFamily: ShellFont,
      },
    },
    legend: {
      itemStyle: {
        fontSize: '12px',
        fontWeight: 'light',
        color: '#757575',
        fontFamily: 'Shell Font, Arial, sans-serif',
      },
    },
    yAxis: {
      title: {
        useHTML: true,
        text: 'Carbon Emission (MTPA)',
        style: CHART_AXIS_STYLE,
      },
    },
    xAxis: {
      categories: uniqueYears as string[],
      plotBands:
        indexOfLastActuals !== -1
          ? [
              {
                color: 'rgba(128, 128, 128, 0.3)', // Gray background for historical section
                from: -point5,
                to: indexOfLastActuals,
                label: {
                  text: 'Actuals',
                  style: {
                    fontFamily: ShellFont,
                    fontSize: '11px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    textAlign: 'right',
                    color: '#343434',
                  },
                  verticalAlign: 'bottom', // Label below the y axis
                  y: 40, // Adjust label position
                },
              },

              {
                color: 'white', // Gray background for historical section
                from: indexOfLastActuals,
                label: planLabel,
              },
            ]
          : [
              {
                color: 'white', // Gray background for historical section
                from: -point5,
                to: uniqueYears.length - 1 + point5, // To final year
                label: planLabel,
              },
            ],
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
      valueDecimals: 2,
      formatter() {
        return toolTipFormatter(this as unknown as PointsData);
      },
    },
    plotOptions: {
      area: {
        stacking: 'normal',
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series:
      updatedGroupedData && updatedGroupedData.length > 0
        ? updatedGroupedData.map((data) => {
            switch (data.type) {
              case 'area':
                return typeBasedChart(data, showEmissionChart);
              case 'line':
                return typeBasedChart(data, showEmissionChart);
              default:
                return {} as Highcharts.SeriesOptionsType;
            }
          })
        : [
            {
              type: 'area',
              name: 'x.name',
              data: [],
            } as Highcharts.SeriesAreaOptions,
          ],
  };
  return (
    <StyledDiv>
      <ChartContainer>
        <Chart
          chartKey={`${chartType === 'basecase' ? 'BaseCase' : 'Scenario'}OverallPortfolio `}
          highcharts={Highcharts}
          options={options}
          source={chartType === 'basecase' ? uniqueSourcesString : SCENERIO_TABLE_SOURCE}
          data-testid='chart'
          disclaimer={[POWER_DISCLAIMER]}
        />
      </ChartContainer>
    </StyledDiv>
  );
};

export default ScenerioModellingChart;
