import { Toggle } from "@sede-x/shell-ds-react-framework"
import { ToggleButton, ToggleButtonDiv } from "../charts/OverallPortfolioChart/index.styles"
import { ToggleText } from "../ScenerioModelling/ScenerioModelling.styles"

const VoumeEmissionToggle = (props: {isToggleOn: () => void}) => {
    return (
        <ToggleButtonDiv>
            <ToggleButton>
                <Toggle
                    label='Volume'
                    mirrored
                    data-testid='toggleButton-overallPortfolio'
                    onChange={() => props.isToggleOn()}
                />
            </ToggleButton>
            <ToggleText>Emissions</ToggleText>
        </ToggleButtonDiv>
    )
}

export default VoumeEmissionToggle;