/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Table,
  THead,
  TR as Tr,
  TH as Th,
  TBody,
  TD as Td,
  TextInput,
  Button,
} from '@sede-x/shell-ds-react-framework';
import '../datavisualization.css';
import { ALL_REGIONS } from '../../../api/constants';
import { IMPORTED_DEALS } from '../../../constants';
interface YearData {
  id: string;
  year: number;
  unit: string;
  originalValue: string | number;
  changedValue: number;
  carbonIntensity: number;
}

interface TableRow {
  id: string;
  category: string;
  product: string;
  yearsdata: YearData[];
}

interface RowGroupsProps {
  tableData: TableRow[];
  selectedRegion: string;
  onInputChange: (
    rowId: string,
    category: string,
    product: string,
    year: number,
    newValue: number,
  ) => void;
  onRevertToOriginal: (rowId: string, category: string, product: string, year: number) => void;
}

const RowGroups: React.FC<RowGroupsProps> = ({
  tableData,
  selectedRegion,
  onInputChange,
  onRevertToOriginal,
}) => {
  const [lastEditedInputField, setLastEditedInputField] = React.useState({});
  // sorted array in the order to deals data comes at end
  tableData.sort((a, b) => {
    if (a.category === IMPORTED_DEALS && b.category !== IMPORTED_DEALS) {
      return 1;
    } else if (a.category !== IMPORTED_DEALS && b.category === IMPORTED_DEALS) {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <div>
      <Table>
        <THead>
          <Tr>
            <Th style={{ textAlign: 'left' }}>KPI</Th>
            {tableData[0]?.yearsdata.map((item) => (
              <Th key={item.year} style={{ textAlign: 'left' }}>
                {item.year}
              </Th>
            ))}
          </Tr>
        </THead>
        <TBody>
          {tableData.map((row, index) => (
            <React.Fragment key={row.id}>
              {index === 0 || row.category !== tableData[index - 1].category ? (
                <Tr key={`category-header-${row.category}`} style={{ background: '#1C1C1C22' }}>
                  <Td colSpan={row.yearsdata.length + 1} style={{ fontWeight: 600 }}>
                    {row.category} ({row.yearsdata.find((data) => data.unit !== '')?.unit})
                  </Td>
                </Tr>
              ) : null}
              <Tr>
                <Td>{row.product}</Td>
                {row.yearsdata.map((yearData) => {
                  const { id, year, changedValue } = yearData;
                  const { category, product } = row;
                  let inputVal: string | number = Math.round(changedValue);
                  // If the input field is being edited, convert the value to a string and remove starting zeros.
                  if(Object.keys(lastEditedInputField).length !== 0) {
                    inputVal = String(inputVal);
                  }
                  return (
                    <Td key={yearData.year}>
                      <div>
                        <div>
                          <TextInput
                            data-testid='scenerio-table-input'
                            type='number'
                            value={inputVal}
                            style={{ textAlign: 'right' }}
                            readOnly={
                              yearData.carbonIntensity === 0 || selectedRegion === ALL_REGIONS
                            }
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              const regex = /^\d*(\.\d{0,2})?$/;
                              if (regex.test(inputValue)) {
                                const parsedValue = inputValue === '' ? 0 : parseFloat(inputValue);
                                onInputChange(id, category, product, year, parsedValue);
                              }
                              // Clear the last edited input field if the user clicks in other input field
                              if(Object.keys(lastEditedInputField).length !== 0) {
                                setLastEditedInputField({});
                              }
                            }}
                            onBlur={() => {setLastEditedInputField({id, changedValue})}}
                          />
                        </div>
                        {yearData.changedValue !== yearData.originalValue &&
                          selectedRegion !== ALL_REGIONS && (
                            <div>
                              <Button
                                data-testid={`scenerio-table-button${yearData.originalValue}`}
                                fullWidth
                                variant='outlined'
                                size='xsmall'
                                style={{ justifyContent: 'flex-end', paddingRight: '14px' }}
                                onClick={() => {
                                  onRevertToOriginal(
                                    yearData.id,
                                    row.category,
                                    row.product,
                                    yearData.year,
                                  )
                                  // Clear the last edited input field if the user clicks on revert button other
                                  setLastEditedInputField({});
                                }
                                }
                              >
                                {Math.round(Number(yearData.originalValue))}
                              </Button>
                            </div>
                          )}
                      </div>
                    </Td>
                  );
                })}
              </Tr>
            </React.Fragment>
          ))}
        </TBody>
      </Table>
    </div>
  );
};

export default RowGroups;
