import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import {
  EQUITY_RENEWABLE_POWER_GENERATION,
  EXTERNAL_POWER_SALES_RENEWABLE_CPPA,
  EXTERNAL_POWER_SALES_RENEWABLE_REC,
  RENEWABLE_ATTRIBUTES_REC,
  THREE_P_RENEWABLE_POWER_SUPPLY_PPA,
} from '../../../api/constants';
import { StackedVerticalBarChartProps, GroupedData } from './types';
import Chart from '../../Chart/Chart';
import { TITLE_FONT_FAMILY } from '../../../constants';
import { getColorByIndex } from '../../../utils';
import { currentYear } from '../../../utils/utils';

const RenewableSalesChart: React.FC<StackedVerticalBarChartProps> = ({
  commodityFilteredValue,
  commodityVal,
  setIsEmptyChart,
}) => {
  const KpiForRenewable = new Set([
    THREE_P_RENEWABLE_POWER_SUPPLY_PPA,
    RENEWABLE_ATTRIBUTES_REC,
    EQUITY_RENEWABLE_POWER_GENERATION,
    EXTERNAL_POWER_SALES_RENEWABLE_CPPA,
    EXTERNAL_POWER_SALES_RENEWABLE_REC,
  ]);

  const commodityNewFilteredValue = commodityFilteredValue;
  const commodityNewFilteredValueCurrentYear = commodityNewFilteredValue.filter(
    (x) => x.TRADE_YEAR === currentYear,
  );

  const commodityNewFilteredValueCurrentYearFiltered = commodityNewFilteredValueCurrentYear.map(
    (item) => {
      if (
        item.TRADE_COMMODITY_NAME === 'Natural Gas' &&
        item.KPI === 'Inland Sales Volumes - Natural Gas'
      ) {
        // Create a new object with the updated KPI property
        return { ...item, KPI: 'External Gas Sales Volumes (Pipeline)' };
      }
      return item; // If TRADE_COMMODITY_NAME is not "Natural Gas", keep the object unchanged
    },
  );

  // Step 1: Filter data based on specified sources
  const filteredData = commodityNewFilteredValueCurrentYearFiltered.filter(
    (item) => item.SOURCE === 'PlanningOne' || item.SOURCE === 'PARIGFinance&Reporting',
  );

  // Step 1: Find unique sources
  const uniqueSources = [...new Set(filteredData.map((item) => item.SOURCE))];

  // Step 2: Concatenate unique sources into a string
  const concatenatedSources = uniqueSources.join(', ');

  const uniqueKPIsSet = new Set(
    commodityNewFilteredValueCurrentYearFiltered.map((item) => item.KPI),
  );

  const renewableKPISet = commodityFilteredValue.some(
    (item) => item.TRADE_COMMODITY_NAME === 'Power',
  )
    ? new Set([...KpiForRenewable])
    : uniqueKPIsSet;

  // Step 2: Group and calculate totals based on KPI
  const groupedData: GroupedData = filteredData.reduce((groups: GroupedData, item) => {
    if (renewableKPISet.has(item.KPI)) {
      const key = item.KPI;
      if (!groups[key]) {
        groups[key] = {
          KPI: key,
          totalQuantity: 0,
          totalCarbonEmission: 0,
        };
      }
      groups[key].totalQuantity += item.QUANTITY;
      groups[key].totalCarbonEmission += item.CARBON_EMISSION;
    }
    return groups;
  }, {});

  // Calculate the total of totalQuantity for all items
  const pieTotal = Object.values(groupedData).reduce(
    (total, item) => total + item.totalQuantity,
    0,
  );

  const title = commodityVal === 'Power' ? 'Renewable Volumes' : 'Gas Volumes';
  let subTitle;

  if (commodityVal === 'Power') {
    subTitle = 'Renewable Volume mix for the current year in TWh.';
  } else if (commodityVal === 'Pipeline Gas') {
    subTitle = `Gas Volume mix or the current year in TWh.`;
  }
  // Convert data to the desired format for Highcharts pie chart
  const pieData = Object.entries(groupedData)
    .reverse()
    .map(([, item], index) => ({
      name: item.KPI,
      y: item.totalQuantity,
      target: pieTotal,
      color: getColorByIndex(index, item.KPI), // Apply custom color based on index
    }));
  // Highcharts options for the pie chart
  const options: Highcharts.Options = {
    chart: {
      type: 'pie',
      height: 500,
      style: {
        fontFamily: 'Shell Font, Arial, sans-serif', // Set 'Shell Font' as the primary font family for the whole chart
      },
    },
    title: {
      text: `${title} (${currentYear})`,
      align: 'left',
      x: 16,
      y: 20,
      style: {
        fontSize: '20px',
        fontFamily: TITLE_FONT_FAMILY, // Set 'Shell Font' for the title
      },
    },
    subtitle: {
      text: `${subTitle}`,
      align: 'left',
      x: 16,
      style: {
        fontSize: '14px',
        fontFamily: TITLE_FONT_FAMILY, // Set 'Shell Font' for the title
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: '<span>{point.name}:<br>{point.y:.2f} TWh, {point.percentage:.1f} % .</span>',
          style: {
            fontSize: '0.8em',
            fontWeight: '400',
            color: 'rgb(38, 38, 38)',
          },
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.percentage:.1f}%</b> of {point.target:.2f} <br/>',
    },

    series: [
      {
        name: 'Renewable Sales',
        type: 'pie',
        data: pieData,
      },
    ],
  };

  const [chartKey, setChartKey] = useState(0);
  const refreshChart = () => {
    setChartKey((prevKey) => prevKey + 1);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    refreshChart();
  }, [concatenatedSources]);
  useEffect(() => {
    pieData.length ? setIsEmptyChart(false) : setIsEmptyChart(true);
  }, [pieData, setIsEmptyChart]);
  return (
    <div style={{ width: '99%', marginTop: '10px' }}>
      {pieData.length ? (
        <Chart
          key={chartKey}
          chartKey={`${title}Salesfor${currentYear}`}
          highcharts={Highcharts}
          options={options}
          source={concatenatedSources}
        />
      ) : null}
    </div>
  );
};

export default RenewableSalesChart;
