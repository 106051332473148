import {
  Button,
  Flexbox,
  Grid,
  Link,
  Modal,
  Pagination,
  DeprecatedTable,
  ButtonLink,
} from '@sede-x/shell-ds-react-framework';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../../Context/AppContext';
import { useNavigate } from 'react-router-dom';
import tableStyles from '../../style.module.css';
import './tableHeadingStyle.css';
import {
  NCF_SNAPSHOT_ID,
  SNAPSHOT_STATUS,
  APPROVED,
  RE_SUBMITTED_FOR_APPROVAL,
  APPROVED_UI,
  DATA_SNAPSHOT_PAGE_DESCRIPTION,
  REJECTED,
  REJECTED_UI,
} from '../../../api/constants';
import { AddNewSnapshotModal } from './AddNewSnapshotModal';
import { InternalServerErrorPage } from '../InternalServerErrorPage/InternalServerErrorPage';
import {
  getUserRegionShortNameForSnapshot,
  handleStatusChange,
  publishTableCols,
  updateSnapshotData,
} from './SnapshotFunctions';
import { ISnapshotData } from '../../../Types/types';

const Container = styled.div`
  width: auto;
  height: auto;
`;

export const DataSnapshotPage = () => {
  const { state, dispatch } = useContext(AppContext).appData;

  const [checkedID, setCheckedID] = useState(-1);
  const [isAddSnapshot, setIsAddSnapshot] = useState(false);
  const [publishPaginationData, setPublishPaginationData] = useState<ISnapshotData[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isSnapshotUpdated, setIsSnapshotUpdated] = useState(false);
  const [isServiceError, setIsServiceError] = useState(false);
  const [cols, setCols] = useState<string[]>([]);
  const [currentPaginationNumber, setCurrentPaginationNumber] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch({
      type: 'setCurrentPage',
      payload: 'Creating an NCF Data Snapshot',
    });
    updateSnapshotData(1, dispatch, {
      setCurrentPaginationNumber,
      userRegionShortName,
      setPublishPaginationData,
      setTotalCount,
      setCheckedID,
      setCols,
      setIsServiceError,
    });
  }, []);

  const userRegionShortName = getUserRegionShortNameForSnapshot(state);
  const userMail = state.userMail;

  return (
    <div data-testid='publishpageid'>
      <Grid gap='24px' areas={['actions actions actions actions', 'table table table table']}>
        <Grid.Cell area='actions'>
          <Grid gap='20px'>
            <Grid.Cell>{DATA_SNAPSHOT_PAGE_DESCRIPTION}</Grid.Cell>
            <Grid.Cell>
              Should changes be required to the data in the Snapshot, then the{' '}
              <Link>
                <ButtonLink onClick={() => navigate('/viewEditReport')}>Data Edit</ButtonLink>
              </Link>{' '}
              feature in Tanso can be used to amend data before creating the Snapshot.
            </Grid.Cell>
          </Grid>
          {state.isUserWithActions && !isServiceError && (
            <Flexbox justifyContent='flex-end'>
              <Button style={styles.publishBtn} onClick={() => setIsAddSnapshot(true)}>
                Create Snapshot
              </Button>

              <Button
                data-testid='resubmitButton'
                style={styles.publishBtn}
                onClick={() =>
                  handleStatusChange(
                    RE_SUBMITTED_FOR_APPROVAL,
                    dispatch,
                    checkedID,
                    currentPaginationNumber,
                    {
                      setIsSnapshotUpdated,
                      setIsServiceError,
                      setCurrentPaginationNumber,
                      userRegionShortName,
                      userMail,
                      setPublishPaginationData,
                      setTotalCount,
                      setCheckedID,
                      setCols,
                    },
                  )
                }
                disabled={checkedID < 0}
              >
                Re-submit Snapshot
              </Button>

              <Button
                data-testid='approvedButton'
                style={styles.publishBtn}
                onClick={() =>
                  handleStatusChange(APPROVED, dispatch, checkedID, currentPaginationNumber, {
                    setIsSnapshotUpdated,
                    setIsServiceError,
                    setCurrentPaginationNumber,
                    userRegionShortName,
                    userMail,
                    setPublishPaginationData,
                    setTotalCount,
                    setCheckedID,
                    setCols,
                  })
                }
                disabled={checkedID < 0}
              >
                Confirm Snapshot Data
              </Button>

              <Button
                data-testid='rejectedButton'
                style={styles.publishBtn}
                onClick={() =>
                  handleStatusChange(REJECTED, dispatch, checkedID, currentPaginationNumber, {
                    setIsSnapshotUpdated,
                    setIsServiceError,
                    setCurrentPaginationNumber,
                    userRegionShortName,
                    userMail,
                    setPublishPaginationData,
                    setTotalCount,
                    setCheckedID,
                    setCols,
                  })
                }
                disabled={checkedID < 0}
              >
                Reject Snapshot
              </Button>
            </Flexbox>
          )}
        </Grid.Cell>
        <Grid.Cell area='table'>
          <div>
            <Flexbox gap='24px 24px' justifyContent='center'>
              <Grid rowGap='24px'>
                <AddNewSnapshotModal
                  isAddSnapshot={isAddSnapshot}
                  setIsAddSnapshot={setIsAddSnapshot}
                  updateSnapshotData={updateSnapshotData}
                  setStateVars={{
                    setCurrentPaginationNumber,
                    userRegionShortName,
                    setPublishPaginationData,
                    setTotalCount,
                    setCheckedID,
                    setCols,
                    setIsServiceError,
                  }}
                />
                <Container>
                  {state.spinner && (
                    <Modal
                      closable={false}
                      loading={true}
                      title={isSnapshotUpdated ? 'Snapshot(s) status updated successfully.' : ''}
                      bodyPadding={true}
                      showFooter={false}
                      open={state.spinner}
                      onClose={() => {
                        //console statement
                      }}
                    ></Modal>
                  )}

                  {isServiceError && <InternalServerErrorPage />}
                  {!isServiceError && (
                    <DeprecatedTable
                      size='small'
                      data={publishPaginationData}
                      rowKey={(record: ISnapshotData) => record[NCF_SNAPSHOT_ID]}
                      columns={publishTableCols(cols, checkedID, setCheckedID)}
                      tableLayout='fixed'
                      wrapperStyle={{ border: '2px solid rgba(0,0,0,0.15)' }}
                      rowClassName={(record: ISnapshotData) =>
                        [APPROVED_UI, REJECTED_UI].includes(record[SNAPSHOT_STATUS])
                          ? tableStyles.confirmSnapshotApprovedRow
                          : tableStyles.confirmSnapshotRow
                      }
                    />
                  )}
                </Container>
                {publishPaginationData.length > 0 && (
                  <Pagination
                    total={totalCount}
                    pageSize={5}
                    onChange={(currentPage: number) =>
                      updateSnapshotData(currentPage, dispatch, {
                        setCurrentPaginationNumber,
                        userRegionShortName,
                        setPublishPaginationData,
                        setTotalCount,
                        setCheckedID,
                        setCols,
                        setIsServiceError,
                      })
                    }
                  />
                )}
              </Grid>
            </Flexbox>
          </div>
        </Grid.Cell>
      </Grid>
    </div>
  );
};

const styles = {
  firstContent: {
    marginRight: 'auto',
    marginTop: '12px',
  },
  publishBtn: {
    margin: '5px',
  },
  wip: {
    width: 200,
    borderRadius: 80,
  },
};
