import styled, { css } from 'styled-components';

const SideAndStatusWrapper = styled.div<{ hasPaddingLeftFromTD?: boolean }>`
  ${({ hasPaddingLeftFromTD }) =>
    hasPaddingLeftFromTD
      ? css``
      : css`
          padding-left: 16px;
        `}
`;

export const StatusLabel = styled.span<{ bgColor: string; textcolor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 16px;
  font-size: 24px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  color: ${({ textcolor }) => textcolor};
  padding: 6px 24px 6px 24px;
`;

export const StatusLabel2 = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  margin-left: 10px;
  text-align: center;
`;

export type SideAndStatusCellProps = {
  statusLabel: string | undefined;
  statusLabel2: string;
  statusBgColor: string;
  textcolor: string;
  hasPaddingLeftFromTD?: boolean;
};

const Carbonemissionstat = ({
  statusLabel2,
  statusLabel,
  statusBgColor,
  textcolor,
  hasPaddingLeftFromTD = true,
}: SideAndStatusCellProps) => (
  <SideAndStatusWrapper hasPaddingLeftFromTD={hasPaddingLeftFromTD} data-testid='lbl-action-status'>
    <StatusLabel textcolor={textcolor} bgColor={statusBgColor}>
      {statusLabel}
     { statusLabel2 &&  <StatusLabel2>{statusLabel2}</StatusLabel2> }
    </StatusLabel>
  </SideAndStatusWrapper>
);

export default Carbonemissionstat;
